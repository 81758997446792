import { ContactForm } from "components/ContactForm";
import { Hero } from "components/Hero";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function ContactPage({ location }) {
  const title = "Contact Us | Plush Oxford";
  const description =
    "We are here to help; that's what we love to do. Message us and we'll get back to you — pronto!";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero
        background={<StaticImage src="../images/heroContact.jpeg" alt="" />}
      >
        <h1>Contact Us</h1>
      </Hero>
      <div className="contact__container">
        <section className="contact__text_block">
          <h2>LOST PROPERTY ENQUIRIES</h2>
          <p>
            For lost property enquiries, WRITE TO us and one of our team members
            will get back to you in 24-48 hours.
          </p>
          <p>
            <a
              className="animated_link"
              href="mailto:lostproperty@plushoxford.com"
            >
              lostproperty@plushoxford.com
            </a>
          </p>
        </section>
        <section className="contact__text_block">
          <h2>CONTACT US</h2>
          <p>(Do Not Use For Lost Property Enquiries)</p>
          <ContactForm />
        </section>
        <section className="contact__text_block">
          <h2>Location</h2>
          <p>
            <small>Address</small>
            <strong>Frewin Court, Oxford OX1 3HZ</strong>
          </p>
          <p>
            <small>Phone</small>
            <strong>01865 247966</strong>
          </p>
          <p>
            <small>Email</small>
            <a className="animated_link" href="mailto:director@plushoxford.com">
              director@plushoxford.com
            </a>
          </p>
        </section>
      </div>
    </>
  );
}
