import React, { useState } from "react";

const endpoint = `https://europe-west2-production-external.cloudfunctions.net/contact`;

export function ContactForm() {
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submit = async (details) => {
    try {
      setSubmitting(true);
      setError("");
      const response = await fetch(endpoint, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-site-id": "site-plush-oxford",
        },
        body: JSON.stringify(details),
      });
      const data = await response.json();
      console.log(data, response.status);
      if (response.status > 300) {
        throw new Error(data?.error);
      }
      setSubmitted(true);
    } catch (e) {
      console.log(e);
      setError(
        e.message ??
          e.error ??
          "Could not send message. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validate = (data) => {
    setFieldErrors({});
    const errors = {};
    Object.keys(data).forEach((key) => {
      if (!data[key].trim()) {
        errors[key] = "Required";
      }
    });
    setError("Some fields are required");
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <form
      className="contact-form"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);

        let data = {
          subject: "Message from plushoxford.com",
        };
        for (let [key, value] of formData.entries()) {
          data[key] = value;
        }

        if (validate(data)) {
          submit(data);
        }
      }}
    >
      <div>
        <label htmlFor="name">NAME</label>
        <input
          data-error={!!fieldErrors["name"]}
          name="name"
          placeholder=" "
          id="name"
        />
      </div>
      <div>
        <label htmlFor="email">EMAIL</label>
        <input
          data-error={!!fieldErrors["email"]}
          name="email"
          placeholder=" "
          required
          id="email"
          type="email"
        />
      </div>
      <div>
        <label htmlFor="message">MESSAGE</label>
        <textarea
          data-error={!!fieldErrors["message"]}
          name="message"
          placeholder=" "
          id="message"
        />
      </div>
      <div>
        <button
          style={{ opacity: submitting ? "0.5" : "1" }}
          type="submit"
          disabled={submitting || submitted}
        >
          SEND MESSAGE
        </button>
      </div>
      {error && <div className="form-error">{error}</div>}
      {submitted && (
        <div className="form-submitted">Thanks, we'll get back asap.</div>
      )}
    </form>
  );
}
